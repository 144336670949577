import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import { message } from "antd";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import CryptoJS from "crypto-js";
import Select from "react-select";

function updateOrAddKey(data, keyName, value) {
  // Check if the key already exists in the object
  if (data.hasOwnProperty(keyName)) {
    // Update the existing key's value
    data[keyName] = value;
  } else {
    // Add the new key-value pair to the object
    data[keyName] = value;
  }
}

const dob_list = [
  1920, 1921, 1922, 1923, 1924, 1925, 1926, 1927, 1928, 1929, 1930, 1931, 1932,
  1933, 1934, 1935, 1936, 1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944, 1945,
  1946, 1947, 1948, 1949, 1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958,
  1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971,
  1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984,
  1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997,
  1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
];
const MissionDetails = ({ setDataShow, dataShow }) => {
  const [gender, setGender] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [email, setEmail] = useState("");
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const formData = new FormData();
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        const { gender, dob, email, id_proff, id_proff_back, photo } =
          resData.data.visitor[0];
          console.log("hello i am here vistior",gender)
        setGender(gender ? gender : "");
        setBirthYear(dob ? dob : "");
        setEmail(email ? email : "");
        setAadharFront(id_proff ? id_proff : null);
        setAadharBack(id_proff_back ? id_proff_back : null);
        setPhoto(photo ? photo : null);
      });
  }, []);
  const validateEmail = (email) => {
    setEmail(email);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log("asdasadad", regex.test(email));
    if (regex.test(email)) {
      setErrorMessage("");

      return true;
    } else {
      setErrorMessage("Email is required and must be valid");
      return false;
    }
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleBirthYearChange = (value) => {
    console.log("asd", value);
    let number = parseInt(value);
    setBirthYear(value);
    // if (
    //   !Number.isInteger(number) ||
    //   parseInt(value) >= 1950 ||
    //   parseInt(value) <= 2050
    // ) {
    //   setErrorMessage("");
    //   return true;
    // } else {
    //   setErrorMessage("enter valid birth year");
    //   return false;
    // }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAadharFrontChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setAadharFront(base64String);
    };
    reader.readAsDataURL(e.target.files[0]);
    // setAadharFront(e.target.files[0]);
  };

  const handleAadharBackChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setAadharBack(base64String);
    };
    reader.readAsDataURL(e.target.files[0]);
    // setAadharBack(e.target.files[0]);
  };

  const handleYourPhotoChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setPhoto(base64String);
    };
    reader.readAsDataURL(e.target.files[0]);
    // setPhoto(e.target.files[0]);
  };
  const handleContinue = (e) => {
    e.preventDefault();
    console.log("hello", aadharFront, aadharBack, photo);
    if (
      !gender ||
      !birthYear ||
      !validateEmail(email) ||
      !aadharFront ||
      !aadharBack ||
      !photo
    ) {
      message.error("all fileds are required");
      return;
    }
    const formData = new FormData();

    formData.append("step", 4);
    formData.append("gender", gender);

    formData.append("dob", birthYear);
    formData.append("email", email);
    formData.append("photo", photo);
    formData.append("idproof", aadharFront);
    formData.append("id_proof_back", aadharBack);
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/register-visitor`, formData)
      .then((resData) => {
        console.log(resData.data, "response");
        // setDataShow(resData.data.visitor[0].step);
        setDataShow(dataShow + 1);
      });
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  const yearTo = new Date("2010-12-31");
  return (
    <div>
      {" "}
      <div class="home-page">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="left-section">
                <div class="rubi-image">
                  <img class="destop-view" src={logo} alt="" />
                  <img class="destop-view size-n1" src={rubiesImage} alt="" />

                  {/* <!-- <img class="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                  <img class="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="right-section">
                <div class="right-top-section">
                  <div class="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: " 50%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>

                  <form>
                    <div class="mb-3 destop-view change-box">
                      <div className="change-box-01">
                        <label for="exampleInputname" class="form-label ">
                          Birth Year
                        </label>
                        <div className="input-group">
                          <Select
                            className="w-30"
                            options={
                              Array.isArray(dob_list) &&
                              dob_list?.map((tag) => ({
                                value: tag,
                                label: tag,
                              }))
                            }
                            value={{
                              label: birthYear ? birthYear : "Select",
                              value: 0,
                            }}
                            onChange={(selectedOption) => {
                              setBirthYear(selectedOption.value);
                            }}
                          />
                        </div>
                        {/* <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.
                                                            </div> --> */}
                      </div>
                      <div className="change-box-02">
                        <label for="exampleInputname" class="form-label ">
                          Gender:-
                        </label>
                        <div class="radio-btn">
                          <div className="radio-box">
                            <input
                              type="radio"
                              id="option1"
                              name="options"
                              value="Male"
                              className="custom-radio"
                              checked={gender === "Male"}
                              onChange={handleGenderChange}
                            />
                            <label for="option1">Male</label>
                          </div>
                          <br />
                          <div className="radio-box">
                            <input
                              type="radio"
                              id="option2"
                              name="options"
                              value="Female"
                              className="custom-radio"
                              checked={gender === "Female"}
                              onChange={handleGenderChange}
                            />
                            <label for="option2"> Female</label>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 destop-view">
                      <label for="exampleInputPassword1" class="form-label">
                        Email Address
                      </label>
                      <input
                        type="mail"
                        class="form-control"
                        id="exampleInputPassword1"
                        value={email}
                        onChange={(e) => validateEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div
                      class="mb-3 mobile-view"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      {/* <input
                        type="name"
                        class="form-control"
                        id="exampleInputName"
                        aria-describedby="datetime"
                        placeholder="Birth year"
                        value={birthYear}
                        onChange={(e) => handleBirthYearChange(e.target.value)}
                        required
                        maxLength={4}
                      /> */}
                      <div className="input-group">
                        <Select
                          className="w-30"
                          options={
                            Array.isArray(dob_list) &&
                            dob_list?.map((tag) => ({
                              value: tag,
                              label: tag,
                            }))
                          }
                          value={{
                            label: birthYear ? birthYear : "Select",
                            value: 0,
                          }}
                          onChange={(selectedOption) => {
                            setBirthYear(selectedOption.value);
                          }}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              "DatePicker",
                              "DatePicker",
                              "DatePicker",
                            ]}
                          >
                            <DatePicker
                              views={["year"]}
                              onChange={(date) => {
                                const parsedDate = new Date(date);
                                setBirthYear(parsedDate.getFullYear());

                                console.log("asdasd", parsedDate.getFullYear());
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider> */}
                      </div>
                      <div class="radio-btn" style={{ paddingLeft: "0px" }}>
                        {/* <span>Gender:-</span> */}
                        <div className="radio-box">
                          <input
                            type="radio"
                            id="option1"
                            name="options"
                            value="Male"
                            className="custom-radio"
                            checked={gender === "Male"}
                            onChange={handleGenderChange}
                          />
                          <label
                            for="option1"
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            Male
                          </label>
                        </div>
                        <br />
                        <div className="radio-box">
                          <input
                            type="radio"
                            id="option2"
                            name="options"
                            value="Female"
                            className="custom-radio"
                            checked={gender === "Female"}
                            onChange={handleGenderChange}
                          />
                          <label
                            for="option2"
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            Female
                          </label>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 mobile-view">
                      <input
                        type="mail"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter Your Email"
                        value={email}
                        onChange={(e) => validateEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                      className="form-group"
                    >
                      <label htmlFor="inputGroupFile"
                       style={{
                        position: 'absolute',
                        background: 'white',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'center',
                        borderRadius: '20px 0px 0px 20px',
                        padding: '4px 10px',
                        marginTop: '4px',
                        marginLeft: '2px',
                         cursor: 'pointer',
                         width: '178px',
                      }}>
                        Upload ID One
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        // id="fileUpload"
                        id="inputGroupFile"
                        onChange={handleAadharFrontChange}
                        required
                        accept=".jpg, .jpeg, .png"
                        className="form-control"
                      />
                    </div>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                      className="form-group"
                    >
                      <label
                        htmlFor="inputGroupFile-1"
                        style={{
                          position: 'absolute',
                          background: 'white',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          // justifyContent: 'center',
                          borderRadius: '20px 0px 0px 20px',
                          padding: '4px 10px',
                          marginTop: '4px',
                          marginLeft: '2px',
                          cursor: 'pointer',
                          width: '178px',
                        }}
                      >
                        Upload ID Two
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        accept=".jpg, .jpeg, .png"
                        id="inputGroupFile-1"
                        onChange={handleAadharBackChange}
                        required
                        className="form-control"
                      />
                    </div>
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      (In case of NRI, please add your passport images)
                    </span>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                      className="form-group"
                    >
                      <label
                        htmlFor="inputGroupFile-2"
                        style={{
                          position: 'absolute',
                          background: 'white',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          // justifyContent: 'center',
                          borderRadius: '20px 0px 0px 20px',
                          padding: '4px 11px',
                          marginTop: '4px',
                          marginLeft: '2px',
                          cursor: 'pointer',
                          width: '178px',
                        }}
                      >
                        Upload Your Photo
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        accept=".jpg, .jpeg, .png"
                        id="inputGroupFile-2"
                        onChange={handleYourPhotoChange}
                        className="form-control"
                        
                      />
                    </div>
                    {/* <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <label>upload Aadhar Front</label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handleAadharFrontChange}
                      />
                    </div>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <label>upload Aadhar Back</label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handleAadharBackChange}
                      />
                    </div>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <label>upload Your Photo</label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handleYourPhotoChange}
                      />
                    </div>
                  */}
                    <div>
                      {errorMessage && (
                        <p className="text-danger">{errorMessage}</p>
                      )}
                    </div>
                    {/* <!-- <div class="mb-3 form-check">
                                                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                            <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                                        </div> --> */}
                    <div class="two-btn" style={{
                      marginTop: '20px',
                    }}>
                      {/* <button
                        onClick={(e) => {
                          setDataShow(dataShow - 1);
                          e.preventDefault();
                        }}
                        class="btn btn-primary btn-primary-01 "
                      >
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Back
                        </a>
                      </button> */}
                      <button
                        onClick={handleContinue}
                        class="btn btn-primary btn-primary-01"
                      >
                        <a>
                          Continue
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionDetails;
