import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import mobileViewHome from "../images/mobile-view-home-image.png";
import decImage from "../images/december-image.png";
import { message } from "antd";
import PrintableContent from "./PrintableContent";

import axios from "axios";
const FirstPage = ({ setDataShow, dataShow }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateName = (name) => {
    // if (!/^[a-zA-Z\s]+$/.test(name)) {
    //   setErrorMessage(
    //     "Name should contain only alphabetic characters and spaces."
    //   );
    //   return false;
    // }
    setErrorMessage("");
    setName(name);
    return true;
  };

  const validatePhone = (phone) => {
    setPhone(phone);

    if (!/^\d{10}$/.test(phone)) {
      setErrorMessage(
        "Phone number should contain exactly 10 numeric characters."
      );
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isNameValid = validateName(name);
    const isPhoneValid = validatePhone(phone);

    if (isNameValid && isPhoneValid) {
      // Submit the form or perform other actions
      const formData = new FormData();
      formData.append("contact", phone);
      formData.append("name", name);

      axios
        .post(`https://jjs.samaksh.in/api/visitor/v2/generate-otp`, formData)
        .then((resData) => {
          console.log("hello student", resData.data);
          console.log("Form submitted!");
          setDataShow(dataShow + 1);
          localStorage.setItem("barcode", resData.data.barcode);
          message.success("Otp send");
        });
    } else {
      message.error("fill valid data");
    }
  };

  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  const [encrypted_data, setEncrypted_data] = useState("");
  const handlePrint = () => {
    const printContents =
      document.getElementById("printable-content").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // To refresh the page after printing
  };
  return (
    <div>
      {" "}
      <div className="home-page">
        <div className="container">
          <div className="row">
           
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  />

                  {/* <!-- <img className="destop-view" src="./images/jjs-logo-image.png" alt=""> --> */}
                  <img
                    className="mobile-view size-n2"
                    src={mobileViewHome}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  {/* <!-- <div className="right-logo">
                                <img className="destop-view" src="./images/logo-01.png">
                            </div> --> */}
                  <img className="destop-view" src={decImage} alt="" />
                  <p>
                    Novotel Jaipur Convention Centre (JECC)
                    <br /> 20-23 december'24
                  </p>
                  <a href="#" className="resister-tag">
                    Register Online For The Show
                  </a>
                </div>
                <form>
                  <div className="mb-3 destop-view">
                    <label for="exampleInputname" className="form-label ">
                      Full Name
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => validateName(e.target.value)}
                      className="form-control"
                      id="exampleInputName"
                      aria-describedby="name"
                    />
                    {/* <!-- <div id="emailHelp" className="form-text">We'll never share your email with anyone else.
                                </div> --> */}
                  </div>
                  <div className="mb-3 destop-view">
                    <label for="exampleInputPassword1" className="form-label">
                      Mobile Number
                    </label>
                    <input
                      type="mobile number"
                      className="form-control"
                      value={phone}
                      onChange={(e) => validatePhone(e.target.value)}
                      id="exampleInputPassword1"
                    />
                  </div>
                  <div className="mb-3 mobile-view">
                    <input
                      type="name"
                      className="form-control"
                      value={name}
                      onChange={(e) => validateName(e.target.value)}
                      id="exampleInputName"
                      aria-describedby="name"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div className="mb-3 mobile-view">
                    <input
                      type="mobile number"
                      className="form-control"
                      id="exampleInputPassword1"
                      value={phone}
                      onChange={(e) => validatePhone(e.target.value)}
                      placeholder="Mobile Number"
                      required
                    />
                  </div>
                  {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                  )}

                  {/* <!-- <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1">
                                <label className="form-check-label" for="exampleCheck1">Check me out</label>
                            </div> --> */}
                  <button
                    className="btn btn-primary"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <a>
                      Continue
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </a>
                  </button>
                </form>

                {/* <div className="progress">
                  <div
                    className="progress-bar "
                    role="progressbar"
                    aria-label="Default striped example"
                    style={{
                      width: " 20%",
                      ariaValuenow: "10",
                      ariaValuemin: "0",
                      ariaValuemax: "100",
                    }}
                  ></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
