import React, { useEffect, useRef, useState } from "react";
import "../css/responsive.css";
import "../css/stylesheet.css";
import FirstPage from "./FirstPage";
import VerifyOtp from "./VerifyOtp";
import DetailsPresent from "./DetailsPresent";
import MissingDetailsOne from "./MissingDetailsOne";
import AddressDetails from "./AddressDetails";
import PaymentPage from "./PaymentPage";
import MissionDetails from "./MissionDetails";
import PaymentDetail from "./PaymentDetail";
import PrintPage from "./PrintPage";
// import logo from "./images/logo-01.png";

const JjsMainPage = () => {
  const [dataShow, setDataShow] = useState(1);
  const [encrypted_data, setEncrypted_data] = useState("");
  const handlePrint = () => {
    const printContents =
      document.getElementById("printable-content").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // To refresh the page after printing
  };

  localStorage.setItem('pay_status',false)
  return (
    <>
      <div id="body">
        {dataShow === 1 && (
          <FirstPage setDataShow={setDataShow} dataShow={dataShow} />
        )}

        {dataShow === 2 && (
          <>
            <VerifyOtp setDataShow={setDataShow} dataShow={dataShow} />
          </>
        )}

        {dataShow === 3 && (
          <>
            <DetailsPresent setDataShow={setDataShow} dataShow={dataShow} />
          </>
        )}
        {dataShow === 4 && (
          <>
            <MissionDetails setDataShow={setDataShow} dataShow={dataShow} />

            {/* <MissingDetailsOne setDataShow={setDataShow} dataShow={dataShow} /> */}
          </>
        )}
        {dataShow === 5 && (
          <>
            {/* <MissionDetails setDataShow={setDataShow} dataShow={dataShow} /> */}

            <MissingDetailsOne setDataShow={setDataShow} dataShow={dataShow} />
          </>
        )}
        {dataShow === 6 && (
          <>
            <AddressDetails
              setDataShow={setDataShow}
              dataShow={dataShow}
              encrypted_data={encrypted_data}
              setEncrypted_data={setEncrypted_data}
            />
          </>
        )}

        {dataShow === 7 && (
          <>
            <PaymentPage
              setDataShow={setDataShow}
              dataShow={dataShow}
              encrypted_data={encrypted_data}
              setEncrypted_data={setEncrypted_data}
            />
          </>
        )}
        {dataShow === 8 && (
          <>
            <PaymentDetail
              setDataShow={setDataShow}
              dataShow={dataShow}
              encrypted_data={encrypted_data}
              setEncrypted_data={setEncrypted_data}
            />
          </>
        )}

        {dataShow === 9 && (
          <>
            <PrintPage
              barcode={localStorage.getItem("barcode")}
              text={"Your registration is complete at jjsjaipur.com."}
            />
            {/* <div className="container mt-5">
              <div className="card bg-light p-4 text-center m-5">
                <h1 className="card-title">Thank you!</h1>
                <p className="card-text">
                  Your registration is complete at jjsjaipur.com.
                </p>
              </div>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default JjsMainPage;
