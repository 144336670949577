import React, { useEffect, useRef, useState } from "react";

import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
import { message } from "antd";
import CryptoJS from "crypto-js";
function updateOrAddKey(data, keyName, value) {
  // Check if the key already exists in the object
  if (data.hasOwnProperty(keyName)) {
    // Update the existing key's value
    data[keyName] = value;
  } else {
    // Add the new key-value pair to the object
    data[keyName] = value;
  }
}
const VerifyOtp = ({ setDataShow, dataShow }) => {
  const inputs = useRef([]);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(5);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [resendCount, setResendCount] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendMessage, setResendMessage] = useState("");

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(intervalId);
            setIsTimerRunning(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isTimerRunning]);


  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Prevent default behavior (adding space to input)
    }
  };
  const handleInputChange = (index, e) => {
    const value = e.target.value;
    console.log(
      "otp",
      otp,
      "value",
      typeof value,
      value === "" && index > 0,
      value && index < 3 && value !== " ",
      value === " "
    );
    setOtp((prev) => prev + value);

    if (value === "" && index > 0) {
      // If the backspace key is pressed and the input is not the first one,
      // move focus to the previous input and remove the character.
      inputs.current[index - 1].focus();
      setOtp((prev) => prev.slice(0, prev.length - 1));
    } else if (value && index < 3 && value !== " ") {
      // If a character is entered and the input is not the last one,
      // move focus to the next input and append the character.
      inputs.current[index + 1].focus();
    }
  };

  useEffect(() => {
    if (otp.length !== 4) {
      return;
    }

    console.log("verify Otp", otp);
    const formData = new FormData();
    formData.append("otp", otp);
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        console.log(resData.data, "response");
        // const userStringfyData = JSON.stringify(userData);

        // Encrypt the string using AES encryption

        setDataShow(resData.data.visitor[0].step);
      })
      .catch((error) => {
        message.error("Invalid Otp");
        setOtp("");
      });
  }, [otp]);

  const handleVerify = (e) => {
    e.preventDefault();
    if (!otp) {
      return;
    }
    console.log("verify Otp", otp);
    const formData = new FormData();
    formData.append("otp", otp);
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        console.log(resData.data, "response");
        setDataShow(resData.data.visitor[0].step);
      });
  };

  const handleResendOTP = (e) => {
    if (resendCount < 3) {
      const formData = new FormData();
      formData.append("barcode", localStorage.getItem("barcode"));
      axios
        .post(`https://jjs.samaksh.in/api/visitor/v2/re-send-otp`, formData)
        .then((resData) => {
          setResendCount((prevCount) => prevCount + 1);
          setTimer(5);
          setIsTimerRunning(true);
        });
    } else {
      setIsResendDisabled(true);
      setResendMessage(
        "You have reached your limit. Please try again after 10 minutes."
      );
      setTimeout(() => {
        setIsResendDisabled(false);
        setResendMessage("");
      }, 600000); // 10 minutes in milliseconds
    }
  };

  return (
    <div>
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  />
                  <img className="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "25%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <form id="nt-1">
                    <div className="content">
                      <h2>Enter your OTP</h2>
                      <div className="otp-input-box">
                        {[0, 1, 2, 3].map((index) => (
                          <input
                            key={index}
                            type="text"
                            name="otp"
                            className="otp-input"
                            maxLength={1}
                            required
                            ref={(el) => (inputs.current[index] = el)}
                            onChange={(e) => handleInputChange(index, e)}
                            onKeyDown={handleKeyDown}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="d-flex">
                      <button
                        className="btn btn-primary"
                        onClick={handleResendOTP}
                        disabled={isTimerRunning || isResendDisabled}
                      >
                        Resend OTP ({timer}s)
                      </button>
                      {/* <button
                        className="btn btn-primary"
                        onClick={handleVerify}
                      >
                        <a>Verify OTP</a>
                      </button> */}
                    </div>
                    <p>{resendMessage}</p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
