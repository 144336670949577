import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import { MultiSelect } from "react-multi-select-component";
import { message } from "antd";
import axios from "axios";
import CryptoJS from "crypto-js";
import Select from "react-select";
function updateOrAddKey(data, keyName, value) {
  // Check if the key already exists in the object
  if (data.hasOwnProperty(keyName)) {
    // Update the existing key's value
    data[keyName] = value;
  } else {
    // Add the new key-value pair to the object
    data[keyName] = value;
  }
}
const MissingDetailsOne = ({ setDataShow, dataShow }) => {
  const [category, setCategory] = useState({ label: "select", value: "0" });
  const [profession, setProfession] = useState({ label: "select", value: "0" });
  const [companyName, setCompanyName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [dealIn, setDealIn] = useState("");
  const [photo, setPhoto] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [contacts_ids, set_deals_ids] = useState([]);
  const [show_default_value, set_show_defalut_vlaue] = useState("");
  const [show_defalut_vlaue_category, set_show_defalut_vlaue_category] =
    useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [gst_status, setGst_status] = useState(false);
  let valueSelected = "";
  const category_list = [
    // { label: "select category", value: "0" },
    { label: "Gem And Jewellery", value: "trader" },
    { label: "Media", value: "media" },
    { label: "NRI", value: "nri" },
    { label: "Visitor", value: "visitor" },
  ];
  const profession_list = [
    // { label: "select profession", value: "0" },
    { label: "Manufacturer", value: "5" },
    { label: "Trader", value: "15" },
    { label: "Broker", value: "1" },
  ];
  const media_profession_list = [
    // { label: "select profession", value: "0" },
    { label: "Print Media", value: "16" },
    { label: "Digital Media", value: "17" },
    { label: "Electronic Media", value: "18" },
  ];
  const build_in_list = [
    { label: "Gold", value: "1" },
    { label: "Silver", value: "2" },
    { label: "Diamond", value: "3" },
    { label: "Costume/Fashion", value: "4" },
    { label: "Precious Stone", value: "5" },
    { label: "Semi-Precious Stones", value: "6" },
    { label: "Synthetic Stones", value: "7" },
    { label: "Others", value: "8" },
  ];

  useEffect(() => {
    const formData = new FormData();
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        const {
          company,
          professions,
          profession_id,
          category,

          gst,
          media_gst_membership_no,
        } = resData.data.visitor[0];
        const filteredList = build_in_list.filter((item) =>
          professions.includes(parseInt(item.value))
        );
        let filterDataProffesion;
        if (category === "media") {
          filterDataProffesion = media_profession_list.filter(
            (item) => item.value === String(profession_id)
          );
        } else {
          filterDataProffesion = profession_list.filter(
            (item) => item.value === String(profession_id)
          );
        }
        const filterDataCategory = category_list.filter(
          (item) => item.value === category
        );
        setCompanyName(company ? company : "");
        setProfession(
          filterDataProffesion[0]
            ? filterDataProffesion[0]
            : { label: "select", value: "z" }
        );
        setCategory(
          filterDataCategory[0]
            ? filterDataCategory[0]
            : { label: "select", value: "z" }
        );
        setSelectedIds(filteredList ? filteredList : []);
        setGstNumber(media_gst_membership_no ? media_gst_membership_no : "");
        setPhoto(gst ? gst : null);
      });
    // const data_decrypted = localStorage.getItem("user_data");

    // const decryptedData = JSON.parse(data_decrypted);
    // const filteredList = build_in_list.filter((item) =>
    //   decryptedData.professions.includes(parseInt(item.value))
    // );

    // const filterDataProffesion = profession_list.filter(
    //   (item) => item.value === String(decryptedData.profession_id)
    // );
    // const filterDataCategory = category_list.filter(
    //   (item) => item.value === decryptedData.category
    // );
    // valueSelected = filterDataProffesion[0]?.label;
    // set_show_defalut_vlaue(filterDataProffesion[0]);
    // set_show_defalut_vlaue_category(filterDataCategory[0]);
    // setProfession(filterDataProffesion[0]);
    // setCategory(filterDataCategory[0]);
    // console.log(
    //   "filterDataProffesion",
    //   filterDataProffesion[0]?.label,
    //   "filterDataCategory",
    //   filterDataCategory[0]?.label,
    //   decryptedData.category
    // );
    // setSelectedIds(filteredList);
    // setCompanyName(decryptedData.company ? decryptedData.company : null);
    // setGstNumber(
    //   decryptedData.media_gst_membership_no
    //     ? decryptedData.media_gst_membership_no
    //     : null
    // );
    // setPhoto(decryptedData.gst ? decryptedData.gst : null);
    // // setCategory(decryptedData.company ? decryptedData.company : null);
    // // setProfession(decryptedData.company ? decryptedData.company : null);
    // // setCompanyName(decryptedData.company ? decryptedData.company : null);
    // // setCompanyName(decryptedData.company ? decryptedData.company : null);

    // console.log("Decrypted Data:", decryptedData);
  }, []);

  useEffect(() => {
    console.log(
      "hello i am he re",
      show_default_value,
      show_defalut_vlaue_category
    );
  }, [show_default_value, show_defalut_vlaue_category]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleProfessionChange = (e) => {
    setProfession(e.target.value);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
    console.log("asdasdasd", e.target.value);
  };

  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }
    function convertToUpperCase(input) {
      return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
    }
  const handleGstNumberChange = (e) => {
    setGstNumber(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      setErrorMessage("");
      console.log("asdasdasd", e.target.value);
    } else {
      setErrorMessage("Provied Valid GSTIN");
    }
  };

  const handleDealInChange = (e) => {
    setDealIn(e.target.value);
  };

  const handlePhotoChange = (e) => {
    // setPhoto(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setPhoto(base64String);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    console.log(
      "checkRegex(gstNumber)",
      checkRegex(gstNumber),
      category,
      profession,
      companyName,
      selectedIds
    );
    // if (
    //   !category ||
    //   !profession ||
    //   !companyName ||
    //   !checkRegex(gstNumber) ||
    //   !photo ||
    //   selectedIds.lenght > 0
    // ) {
    //   message.error("Fill All Valid Fields");
    //   return;
    // }
    if (category.value === "trader") {
      if (
        !category ||
        !profession ||
        !companyName ||
        !checkRegex(gstNumber) ||
        !photo ||
        selectedIds.length < 1
      ) {
        message.error("Fill All Valid Fields");
        return;
      }
    } else if (category.value === "media") {
      if (!companyName || !profession) {
        message.error("Fill All Valid Fields");
        return;
      }
    } else if(category.value==="z") {
      message.error("Fill All Valid Fields");
      return;
      // For other categories, store the category and call the API
    }

    message.success("done");
    // setDataShow(dataShow + 1);

    const formData = new FormData();
    const valueOfDealIn = [];
    for (let i = 0; i < selectedIds.length; i++) {
      formData.append("professions[]", selectedIds[i].value);
      valueOfDealIn.push(parseInt(selectedIds[i].value));
    }
    // formData.append("step", 5);
    // formData.append("category", category.value);
    // formData.append("company", companyName);
    // formData.append("media_gst_membership_no", gstNumber);
    // formData.append("profession", profession.value);
    // formData.append("gst", photo);
    // for (let i = 0; i < selectedIds.length; i++) {
    formData.append("professions[]", 1);
    // }
    formData.append("step", 5);
    formData.append("category", category.value);
    formData.append("company", companyName);
    formData.append("media_gst_membership_no", gstNumber);
    formData.append("profession", profession.value);
    formData.append("gst", photo);

    formData.append("barcode", localStorage.getItem("barcode"));
    // const obj = JSON.parse(localStorage.getItem("user_data"));
    // updateOrAddKey(obj, "category", category.value);
    // updateOrAddKey(obj, "company", companyName);
    // updateOrAddKey(obj, "media_gst_membership_no", gstNumber);
    // updateOrAddKey(obj, "profession_id", profession.value);
    // if (photo) {
    //   updateOrAddKey(obj, "gst", true);
    // } else {
    //   updateOrAddKey(obj, "gst", false);
    // }

    // updateOrAddKey(obj, "professions", valueOfDealIn);
    // // localStorage.setItem("user_data", JSON.stringify(obj));
    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/register-visitor`, formData)
      .then((resData) => {
        console.log(resData.data, "response");
        // setDataShow(resData.data.visitor[0].step);
        setDataShow(dataShow + 1);
      });
    // setDataShow(dataShow + 1);
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  useEffect(() => {
    console.log("hello i am here", selectedIds);
    set_deals_ids(selectedIds.map((item) => item.value));
  }, [selectedIds]);
  return (
    <div>
      {" "}
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  />

                  {/* <!-- <img className="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                  <img className="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  <div className="progress">
                    <div
                      className="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "75%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <form>
                    <label for="cars">Category

                    <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      className=" mb-3 form-control"
                      value={{
                        label: category?.label ? category?.label : "select",
                        value: category?.value,
                      }}
                      options={category_list.map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      onChange={(selectItem) => {
                        setProfession({ label: "select", value: "0" });
                        setCompanyName("");
                        setGstNumber("");
                        setPhoto(null);
                        setSelectedIds([]);
                        setErrorMessage("");
                        let value = selectItem.value;
                        setCategory(selectItem);
                      }}
                    />

                    {(category.value === "media" ||
                      category.value === "trader") && (
                      <>
                        <label for="cars">Profession</label>
                        {console.log("show_default_value", show_default_value)}
                        {category.value === "media" ? (
                          <Select
                            className=" mb-3 form-control"
                            value={{
                              label: profession?.label
                                ? profession?.label
                                : "select",
                              value: profession?.value,
                            }}
                            options={media_profession_list.map((item) => ({
                              label: item.label,
                              value: item.value,
                            }))}
                            // value={show_default_value}
                            onChange={(selectItem) => {
                              let value = selectItem.value;
                              setProfession(selectItem);
                              // setProfession(value !== "0" ? value : null);
                            }}
                          />
                        ) : (
                          <Select
                            className=" mb-3 form-control"
                            value={{
                              label: profession?.label
                                ? profession?.label
                                : "select",
                              value: profession?.value,
                            }}
                            options={profession_list.map((item) => ({
                              label: item.label,
                              value: item.value,
                            }))}
                            // value={show_default_value}
                            onChange={(selectItem) => {
                              let value = selectItem.value;
                              setProfession(selectItem);
                              // setProfession(value !== "0" ? value : null);
                            }}
                          />
                        )}
                      </>
                    )}
                    {(category.value === "media" ||
                      category.value === "trader") && (
                      <>
                        <div className="mb-3 destop-view">
                          <label for="exampleInputname" className="form-label ">
                            Company Name
                          </label>
                          <input
                            type="Company namee"
                            className="form-control"
                            id="exampleInputName"
                            aria-describedby="name"
                            required
                            value={companyName}
                            onChange={handleCompanyNameChange}
                          />
                        </div>
                      </>
                    )}
                    {category.value === "trader" && (
                      <>
                        <div className="mb-3 destop-view">
                          <label
                            for="exampleInputPassword1"
                            className="form-label"
                          >
                            GST Number
                          </label>
                          <input
                            type="mail"
                            className="form-control"
                            id="exampleInputPassword1"
                            required
                            value={gstNumber}
                            maxLength={15}
                            onChange={handleGstNumberChange}
                          />
                        </div>
                      </>
                    )}

                    {(category.value === "media" ||
                      category.value === "trader") && (
                      <div className="mb-3 mobile-view">
                        <input
                          type="name"
                          className="form-control"
                          id="exampleInputName"
                          aria-describedby="datetime"
                          placeholder="Company Name"
                          required
                          value={companyName}
                          onChange={handleCompanyNameChange}
                        />
                      </div>
                    )}
                    {category.value === "trader" && (
                      <>
                        <div className="mb-3 mobile-view">
                          <input
                            type="mail"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="GST Number"
                            required
                            value={gstNumber}
                            maxLength={15}
                            onChange={handleGstNumberChange}
                          />
                        </div>
                        <label for="cars">deal in</label>
                        <MultiSelect
                          className=" mb-3 form-control"
                          options={build_in_list.map((item, index) => ({
                            label: item.label,
                            value: item.value,
                          }))}
                          value={selectedIds}
                          onChange={(selectedOption) => {
                            setSelectedIds(selectedOption);
                            console.log("line no 110 ", selectedOption);
                            // handleRoleSelectChange(selectedOption)
                          }}
                        />
                        {/* <input type="file" class="form-control" ></div> */}
                        <div
                          action="/upload"
                          method="post"
                          enctype="multipart/form-data"
                          className="form-group"
                        >
                          <label
                            htmlFor="inputGroupFile"
                            style={{
                              position: "absolute",
                              background: "white",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: 'center',
                              borderRadius: "20px 0px 0px 20px",
                              padding: "4px 10px",
                              marginTop: "4px",
                              marginLeft: "2px",
                              cursor: "pointer",
                              width: "178px",
                            }}
                          >
                            Upload GST
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            name="fileUpload"
                            // id="fileUpload"
                            id="inputGroupFile"
                            onChange={handlePhotoChange}
                            required
                            accept=".jpg, .jpeg, .png"
                            className="form-control"
                          />
                        </div>
                        {/* <div
                          action="/upload"
                          method="post"
                          enctype="multipart/form-data"
                          className="form-group"
                        >
                          <label class="fw-bold">Upload Gst</label>
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            name="fileUpload"
                            className="form-control"
                            id="fileUpload"
                            onChange={handlePhotoChange}
                          />
                          <!-- <button type="submit">Upload</button> -->
                        </div> */}
                      </>
                    )}
                    {/* <!-- <div className="mb-3 form-check">
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck1">
                                                            <label className="form-check-label" for="exampleCheck1">Check me out</label>
                                                        </div> --> */}
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                    <div className="two-btn mt-3">
                      <button className="btn btn-primary btn-primary-01 ">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setDataShow(dataShow - 1);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Back
                        </a>
                      </button>
                      <button
                        className="btn btn-primary btn-primary-01"
                        type="submit"
                        onClick={handleContinue}
                      >
                        Continue
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissingDetailsOne;
