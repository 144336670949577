import React from "react";
import { useLocation } from "react-router-dom";
import PrintPage from "./PrintPage";

const SuccessfulRedirection = () => {
  // const location = useLocation();
  // const postData = new URLSearchParams(location.search);

  // console.log("postData", postData);
  // // Access the POST data using get method
  // const dataValue = postData.get("dataKey");

  return (
    <>
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-md-6">
            <div className="card shadow">
              <div className="card-body text-center">
                <h2 className="card-title mb-4">Payment Successful</h2>
                {/* <div className="checkmark-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="120"
                  height="120"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="green"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="bi bi-check-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9 12l2 2 4-4" />
                </svg>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrintPage
        barcode={localStorage.getItem("barcode")}
        text={"Thank you for your payment."}
      />
    </>
  );
};

export default SuccessfulRedirection;
