import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
const DetailsPresent = ({ setDataShow, dataShow }) => {
  const [user, setUser] = useState(null);
  const [user_deal_in, setUser_deal_in] = useState(null);
  const handleContinue = (e) => {
    window.location.reload();
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  const category_list = [
    // { label: "select category", value: "0" },
    { label: "JEM AND JEWELLERY", value: "trader" },
    { label: "MEDIA", value: "media" },
    { label: "NRI", value: "nri" },
    { label: "GENERAL VISITOR", value: "visitor" },
  ];
  const profession_list = [
    // { label: "select profession", value: "0" },
    { label: "MANUFACTURER", value: "5" },
    { label: "TRADER", value: "15" },
    { label: "BROKER", value: "1" },
  ];
  const build_in_list = [
    { label: "Gold", value: "1" },
    { label: "Silver", value: "2" },
    { label: "Diamond", value: "3" },
    { label: "Costume/Fashion", value: "4" },
    { label: "Precious Stone", value: "5" },
    { label: "Semi-Precious Stones", value: "6" },
    { label: "Synthetic Stones", value: "7" },
    { label: "Others", value: "8" },
  ];
  useEffect(() => {
    const formData = new FormData();
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        const dataSetUser = resData.data.visitor[0];
        setUser(dataSetUser);
        setUser_deal_in(
          dataSetUser.professions
            .map((value) => {
              const item = build_in_list.find(
                (obj) => obj.value === value.toString()
              );
              return item ? item.label : null;
            })
            .filter((label) => label !== null)
        );
      });
  }, []);
  // useEffect(() => {
  //   console.log("hello i am here", user_deal_in);
  // }, [user_deal_in]);
  const getLabel = (value, type) => {
    console.log("hello i am here", value);
    if (type === "category") {
      const category = category_list.find((item) => item.value === value);
      return category ? category.label : "Unknown";
    } else if (type === "profession") {
      const category = profession_list.find(
        (item) => parseInt(item.value) === value
      );
      return category ? category.label : "Unknown";
    }
  };
  return (
    <div>
      {" "}
      {user ? (
        <div className="home-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="left-section">
                  <div className="rubi-image">
                    <img className="destop-view" src={logo} alt="" />
                    <img
                      className="destop-view size-n1"
                      src={rubiesImage}
                      alt=""
                    />

                    {/* <!-- <img className="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                    <img className="mobile-view" src={logoWithRuby} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="right-section">
                  <div className="right-top-section">
                    <form>
                      <div className="detials">
                        <h2>Confirm your Details</h2>
                      </div>
                      <data className="cutomer-details">
                        <span>name :- {user.name}</span>
                        <span>number :- {user.contact}</span>
                        <span>birth year :- {user.dob}</span>
                        <span>e-mail address :-{user.email}</span>
                        <span>
                          category :- {getLabel(user.category, "category")}
                        </span>
                        <span>
                          profession :-{" "}
                          {getLabel(user.profession_id, "profession")}
                        </span>
                        <span>deal in :- {user_deal_in.join(", ")}</span>
                        <span>
                          Gst number :- {user.media_gst_membership_no}
                        </span>
                        <span>company name :- {user.company}</span>
                        <span>city :- {user.city}</span>
                        <span>address :- {user.address}</span>
                        <span>pincode :- {user.pincode}</span>
                      </data>
                      <div className="two-btn">
                        {/* <button
                        type="submit"
                        className="btn btn-primary btn-primary-01 "
                        onClick={(e) => {
                          setDataShow(2);

                          e.preventDefault();
                        }}
                      >
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Back
                        </a>
                      </button> */}
                        <button
                          className="btn btn-primary btn-primary-01"
                          onClick={handleContinue}
                        >
                          <a>
                            Thanks!
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                              />
                            </svg> */}
                          </a>
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <div className="progress">
                    <div
                      className="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: " 20%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

export default DetailsPresent;
