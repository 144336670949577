import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import { message } from "antd";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Spinner } from "react-bootstrap";

const AddressDetails = ({
  setDataShow,
  dataShow,
  encrypted_data,
  setEncrypted_data,
}) => {
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const handlePincodeChange = (e) => {
    const value = e.target.value;
    setPincode(value);

    if (/^\d+$/.test(value) || value === "") {
      setPincodeError("");
    } else {
      setPincodeError("Pincode must contain only numbers");
    }
  };

  useEffect(() => {
    if (pincode?.length === 6) {
      handleBlur();
    }
  }, [pincode]);

  const handleFocus = () => {
    setCity("");
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        const { city, address, pincode } = resData.data.visitor[0];
        setCity(city);
        setAddress(address);
        setPincode(pincode);
      });
  }, []);

  const handleContinue = (e) => {
    e.preventDefault();
    if (!isTermsAccepted) {
      message.error("You must accept the terms and conditions.");
      return;
    }

    console.log("ghello", city, address, pincode);
    if (!city || !address || !pincode) {
      message.error("Fill all fields");
      return;
    }
    const formData = new FormData();

    formData.append("step", 6);
    formData.append("city", city ? city : "");
    formData.append("address", address ? address : "");
    formData.append("pincode", pincode ? pincode : "");
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/register-visitor`, formData)
      .then((resData) => {
        console.log(resData.data, "response");
        if (resData.data.payment_code) {
          setDataShow(dataShow + 1);
          setEncrypted_data(resData.data.payment_code);
        } else {
          setDataShow(9);
        }
      });
  };

  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);

  const handleBlur = () => {
    setShowSpinner(true);
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/get-city-from-pincode`, {
        pincode,
      })
      .then((resData) => {
        setTimeout(() => {
          setCity(resData?.data?.data);
          setShowSpinner(false);
        }, 1000); // Set the spinner to false after 1 second
      });
  };

  return (
    <div>
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  />
                  <img className="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{ width: "100%" }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <form>
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Pincode
                      </label>
                      <input
                        type="mail"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={pincode}
                        maxLength={6}
                        onFocus={handleFocus}
                        // onBlur={handleBlur}
                        onChange={handlePincodeChange}
                      />
                    </div>
                    <div className="mb-3 destop-view">
                      <label htmlFor="exampleInputname" className="form-label">
                        City
                      </label>
                      <input
                        type="Company namee"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="name"
                        required
                        value={city}
                        disabled
                      />
                      {showSpinner && (
                        <Spinner
                          style={{
                            position: "absolute",
                            right: "110px",
                            top: "237px",
                          }}
                        />
                      )}
                    </div>
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Address
                      </label>
                      <input
                        type="mail"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="mail"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Pincode"
                        required
                        value={pincode}
                        maxLength={6}
                        onFocus={handleFocus}
                        // onBlur={handleBlur}
                        onChange={handlePincodeChange}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="name"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="datetime"
                        placeholder="City"
                        required
                        disabled
                        value={city}
                      />
                      {showSpinner && (
                        <Spinner
                          style={{
                            position: "absolute",
                            right: "40px",
                            top: "303px",
                          }}
                        />
                      )}
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="mail"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <input
                      type="checkbox"
                      id="option1"
                      name="options"
                      value="option1"
                      checked={isTermsAccepted}
                      onChange={(e) => setIsTermsAccepted(e.target.checked)}
                    />
                    <span> I Agree All Statement In Terms And Condition</span>
                    {pincodeError && (
                      <p className="text-danger">{pincodeError}</p>
                    )}
                    <div className="two-btn">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setDataShow(dataShow - 1);
                        }}
                        className="btn btn-primary btn-primary-01"
                      >
                        <a>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Back
                        </a>
                      </button>
                      <button
                        onClick={handleContinue}
                        className="btn btn-primary btn-primary-01"
                      >
                        <a>
                          Continue
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
